<template>
  <div class="w-full">
    <ComplaintsSampleMonitoring v-if="sample" :sample="sample" :loading="loadingSampleReport"
    @download-report-samples="onGetAllComplaints" :jsoncsv="json_csv" :jsondata="json_data" :actual="complaints"/>
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div v-if="loading" class="spinner">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <div
      v-if="complaints.length > 0"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4"
    >
      <a-table
        :columns="columns"
        rowKey="codigo_queja"
        :data-source="complaints"
        :pagination="false"
      >
        <div slot="monitoring" slot-scope="text, record">
          <button
            class="flex justify-center col-span-1 text-xs pr-2 text-blue h-min"
            @click="managementComplaint($event, record)"
          >
            <img src="@/assets/icons/eye.svg" class="h-5 my-auto mr-2" alt="icono de ojo"/>
            <b class="my-auto">Ver</b>
          </button>
        </div>
        <div slot="actions" slot-scope="text, record">
          <BaseButton
            @click.native="goManagement(text, record)"
            label="Gestionar"
          />
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-less-items
          size="large"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ModalConfirmation from "@/components/Dashboard/Users/ModalConfirmation.vue";
import ComplaintsSampleMonitoring from "./ComplaintsSampleMonitoring";
import ComplaintStatusLabel from "../ComplaintStatusLabel";

const columns = [
  {
    title: "Id. de radicado",
    dataIndex: "codigo_queja",
    key: "codigo_queja",
  },
  {
    title: "Entidad",
    dataIndex: "entity_name",
    key: "entity_name",
  },
  {
    title: "Estado actual",
    dataIndex: "complaint_state",
    key: "complaint_state",
  },
  {
    title: "Evaluado",
    dataIndex: "revised_name",
    key: "revised_name",
  },
  {
    title: "Histórico de monitoreo",
    dataIndex: "monitoring",
    key: "monitoring",
    scopedSlots: { customRender: "monitoring" },
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: {
    ModalConfirmation,
    ComplaintsSampleMonitoring,
  },
  data() {
    return {
      sample: null,
      columns,
      legend: "",
      count: 0,
      complaints: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      page: 1,
      showModal: false,
      pageSize: 100,
      criterion_id: null,
      sample_id: null,
      pageSizeOptions: ["50", "100", "150", "200", "250"],
      json_data: [],
      json_csv: [],
      pageSizeDownload: 1000,
      loadingSampleReport: false,
    };
  },
  mounted() {
    this.sample_id = this.$route.query.sample_id;
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }

    this.fetchComplaintsSample(this.pageSize, this.page);
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, sample_id: this.sample_id },
        });
        this.page = newVal;
      }
    },
    "$route.query.page": function () {
      this.fetchComplaintsSample();
    },
  },
  methods: {
    async complaintDetails(checked, record) {
      this.loading = true;
      let { error, data } = await this.$api.getComplaintQuery(
        `?codigo_queja=${record.codigo_queja}`
      );
      this.loading = false;

      if (error)
        this.showToast("error", "Error recuperando información de la queja");

      if (data) {
        const complaint = data.results[0];
        this.$router.push({
          name: "ComplaintDetails",
          query: { complaint_id: complaint?.codigo_queja },
        });
      }
    },
    async fetchComplaintsSample(pageSize = 5, page = 1) {
      let query = `?page_size=${this.pageSize}&page=${this.page}&`;

      this.loading = true;
      let { error, data } = await this.$api.getSample(this.sample_id, query);
      this.loading = false;

      if (error) this.legend = "Ocurrió un error con la consulta";

      if (data) {
        const sample = data;
        this.count = sample.count;
        this.criterion_id = sample.criterion_id;

        this.sample = {
          criterion_name: sample.criterion_name,
          total: sample.total_complaints,
          progress: sample.progress,
          percent: (100 * sample.progress) / sample.total_complaints,
        };

        if (this.count != 0) {
          this.legend = "";
          this.complaints = data.results.map((complaint) => {
            return {
              ...complaint,
              complaint_state: (
                <ComplaintStatusLabel
                  left={true}
                  status={complaint.complaint_state}
                  danger="true"
                />
              ),
            };
          });
        } else {
          this.complaints = [];
          this.legend = "No se encontraron resultados";
        }
      }
    },
    managementComplaint(checked, record) {
      this.$router.push(
        `/dashboard/complaints/management/monitoring/${record.codigo_queja}/`
      );
    },
    goManagement(text, record) {
      this.$router.push(
        `/dashboard/complaints/management/sample/detail/${record.codigo_queja}/${this.criterion_id}`
      );
    },
    onGetAllComplaints(isCsv = false) {
      // Se realiza una primera petición para determinar el total de registros a descargar
      this.loadingSampleReport = true;

      let pages_all = Math.trunc(this.count / this.pageSizeDownload);
      let mod = this.count % this.pageSizeDownload > 0 ? 1 : 0;
      pages_all += mod;
      const promises = [];
      this.json_data = [];
      this.json_csv = [];
      for (let i = 0; i < pages_all; i++) {
        let query = `?page_size=${this.pageSizeDownload}&page=${i+1}`;
        promises.push(this.$api.getSample(this.sample_id, query));
      }
      // Ejecutamos todas las peticiones.
      Promise.allSettled(promises)
        .then((response) => {
          let data = [];
          for (let i = 0; i < pages_all; i++) {
            const complaint_search = response[i]?.value?.data?.results;
            data = [...data, ...complaint_search];
          }

          this.loadingSampleReport = false;
          if (isCsv) this.json_csv = data;
          else this.json_data = data;
        })
        .catch((error) => {
          this.loadingSampleReport = false;
          this.showToast("error", error);
        });
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style scoped>
.spinner {
  width: 100%;
  z-index: 9000;
  margin-top: 10%;
  position: absolute;
  display: flex;
  justify-content: center;
}
</style>